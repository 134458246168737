export default {
  tenant: {
    contacts: {
      actions: {
        addContact: "Add contact details",
        editContact: "Edit contact details",
        removeContact: "Remove contact details"
      },
      contactTypes: {
        sales: "Sales",
        service: "Service"
      },
      dataTable: {
        headers: {
          address: "Address",
          name: "Name",
          phoneNumber: "Phone number",
          type: "Type"
        }
      },
      dialogs: {
        edit: {
          title: "Edit contact"
        }
      },
      messages: {
        contactAdded: "New contact has been added",
        contactUpdated: "Contact has been updated",
        removed: "Contact has been removed"
      },
      title: "Contacts"
    },
    customer: {
      actions: {
        addCustomer: "Add customer details",
        addDeliveryAddress: "Add delivery address",
        editCustomer: "Edit customer details",
        editDeliveryAddress: "Edit delivery address",
        removeCustomer: "Remove customer details"
      },
      labels: {
        addDetailsQuestion:
          "Do you want to add customer details for this tenant? This will create a customer number for the web shop.",
        address: "Address",
        addressDialogDescription:
          "Enter the customer's delivery address details.",
        addressLine: "Address line",
        customerNo: "Customer number",
        customerType: "Customer type",
        dealer: "Dealer",
        dealerAddressDescription:
          "The dealer's address will be fetched from Monitor by the web shop",
        deliveryAddressDescription:
          "Addresses to which web shop orders can be shipped. A maximum of {maxRecords} addresses can be added.",
        deliveryAddressInfo:
          "Cannot add any more delivery addresses. Max number of addresses have been entered.",
        deliveryAddresses: "Delivery addresses",
        discount: "Web shop discount percentage",
        endCustomer: "End customer",
        endCustomerName: "End customer name",
        endCustomerNo: "End customer number",
        freightContact: "Freight contact",
        missingCountry:
          "A country must be specified before customer details can be added. Please select one in the Tenant profile view.",
        priceListId: "Web shop price list ID"
      },
      messages: {
        removed: "Customer details have been removed",
        saved: "Customer details have been saved"
      },
      placeholders: {
        address: "Enter address",
        customerNo: "Enter a Monitor customer number",
        customerType: "Choose customer type",
        discount: "Enter web shop discount (0-100 %)",
        freightContact: "Enter freight contact",
        priceListId: "Enter web shop price list ID"
      },
      title: "Customer"
    },
    dataTable: {
      headers: {
        city: "City",
        country: "Country",
        description: "Description",
        name: "Name"
      }
    },
    dialogs: {
      edit: {
        addTitle: "Add Tenant",
        city: "City",
        cityPlaceholder: "Enter city name",
        country: "Country",
        countryPlaceholder: "Select country",
        description: "Description",
        descriptionPlaceholder: "Enter description",
        editTitle: "Edit Tenant",
        engineHoursBeforeNextService: "Engine hours before next service",
        enterEmailAddress: "Enter email address",
        messages: {
          added: "Tenant has been added",
          parentChanged: "Tenant's parent has been changed",
          updated: "Tenant has been updated"
        },
        name: "Name",
        namePlaceholder: "Enter tenant name",
        sendMaintanenceNotifications:
          "Send maintenance related email notifications."
      }
    },
    itemName: "tenant",
    menuItems: {
      downloads: "Downloads",
      fleetAnalytics: "Fleet Analytics",
      fleet_analytics: "Fleet Analytics Dashboard",
      machines: "Machines",
      manuals: "Manuals",
      manualsProductId: "Manuals for Brokk ",
      map: "Map",
      reports: "Reports",
      tenants: "Tenants",
      users: "Users"
    },
    noLocationData: "No location data to display",
    profile: {
      title: "Tenant profile"
    },
    selectAnObject: "Please select an object in the tree view."
  }
}
