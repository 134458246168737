export default {
  $vuetify: {
    badge: "Distintivo",
    calendar: {
      moreEvents: "{0} más"
    },
    carousel: {
      ariaLabel: {
        delimiter: "Diapositiva de carrusel {0} de {1}"
      },
      next: "Siguiente visual",
      prev: "Visual anterior"
    },
    close: "Cerrar",
    dataFooter: {
      firstPage: "Primera página",
      itemsPerPageAll: "Todos",
      itemsPerPageText: "Elementos por página:",
      lastPage: "Última página",
      nextPage: "Página siguiente",
      pageText: "{0}-{1} de {2}",
      prevPage: "Página anterior"
    },
    dataIterator: {
      loadingText: "Cargando... Espera...",
      noResultsText: "No se encontraron registros coincidentes"
    },
    dataTable: {
      ariaLabel: {
        activateAscending: "Activar orden ascendente.",
        activateDescending: "Activar orden descendente.",
        activateNone: "Activar para eliminar el orden.",
        sortAscending: "Orden ascendente.",
        sortDescending: "Orden descendente.",
        sortNone: "Sin ordenar."
      },
      itemsPerPageText: "Filas por página:",
      sortBy: "Ordenar por"
    },
    datePicker: {
      itemsSelected: "{0} seleccionado",
      nextMonthAriaLabel: "Próximo mes",
      nextYearAriaLabel: "Próximo año",
      prevMonthAriaLabel: "Mes anterior",
      prevYearAriaLabel: "Año anterior"
    },
    fileInput: {
      counter: "{0} archivos",
      counterSize: "{0} archivos ({1} en total)"
    },
    noDataText: "Datos no disponibles",
    pagination: {
      ariaLabel: {
        currentPage: "Página actual Página {0}",
        next: "Página siguiente",
        page: "Ir a la página {0}",
        previous: "Página anterior",
        wrapper: "Navegación de paginación"
      }
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    }
  },
  chart: {
    legend: {
      average: "Promedio",
      hours: "Horas",
      max: "Máx.",
      pressure: "Presión",
      temperature: "Temperatura"
    }
  },
  common: {
    actions: {
      add: "Añadir",
      apply: "Aplicar",
      applyChanges: "Aplicar cambios",
      cancel: "Cancelar",
      close: "Cerrar",
      download: "Descargar",
      edit: "Editar",
      exportAsCSV: "Exportar como csv",
      goBack: "Retroceder",
      hide: "Ocultar",
      move: null,
      next: "Siguiente",
      no: "No",
      ok: "Aceptar",
      openInNewWindow: "Se abre en una ventana nueva",
      previous: "Anterior",
      refresh: "Actualizar",
      remove: "Eliminar",
      retry: "Reintentar",
      save: "Guardar",
      show: "Mostrar",
      transfer: "Transferir",
      view: "Ver",
      yes: "Sí"
    },
    allRightsReserved: "Todos los derechos reservados",
    dataTable: {
      headers: {
        actions: "Acciones"
      }
    },
    default: "Por defecto",
    dialogs: {
      confirmRemove: {
        confirm: "¿Estás seguro de que deseas eliminar el {0}?",
        note: "Nota: Esta acción no se puede deshacer.",
        title: "Eliminar {0}"
      }
    },
    enums: {
      day: {
        now: "Ahora",
        today: "Hoy",
        yesterday: "Ayer"
      },
      measurementSystem: {
        imperial: "Imperial",
        metric: "Métrico"
      },
      time: {
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos"
      }
    },
    error: {
      causedBy: "Causado por",
      details: "Detalles",
      more: "Más",
      request: "Solicitud"
    },
    exceptions: {
      error: "Error"
    },
    filters: {
      customRange: "Rango personalizado",
      filter: "Filtrar",
      last: "Último»",
      timeRange: "Intervalo de tiempo"
    },
    invalidUsage: "Uso no válido",
    language: "Idioma",
    messages: {
      noDataToExport:
        "No hay datos para exportar. Selecciona un intervalo de tiempo distinto.",
      recordsExported: "Se exportaron {number} registros"
    },
    placeholders: {
      email: "Introducir dirección de correo electrónico",
      selectDates: "Seleccionar fechas"
    },
    saveSuceeded: "Se han guardado los cambios",
    units: {
      day: "{count} días | {count} día | {count} días",
      daysAgo: "hace {count} días | hace {count} día | hace {count} días",
      hour: "{count} horas | {count} hora | {count} horas",
      hourShort: "H",
      month: "{count} meses | {count} mes | {count} meses",
      second: "{count} segundos | {count} segundo | {count} segundos",
      week: "{count} semanas | {count} semana | {count} semanas"
    },
    validation: {
      errors: {
        betweenInvalid: "El valor debe estar entre {MIN} y {MAX}",
        commentMaxChars: "El comentario debe tener 256 caracteres o menos",
        customerNoInvalid: "Introduzca un número de cliente válido ",
        discountInvalid: "El valor debe ser numérico y estar entre 0 y 100",
        emailInvalid: "Dirección de correo electrónico no válida",
        emailLengthInvalid:
          "Longitud de la dirección de correo electrónico no válida.",
        engineHoursInvalid: "Horas del motor no válidas",
        fieldIsRequired: "Obligatorio",
        fieldLengthBetween: "Debe tener entre {MIN} y {MAX} caracteres",
        fieldMustBeginWithWord: "Debe comenzar con un carácter alfabético",
        latitudeInvalid: "La latitud debe ser un número entre -90 y 90",
        longitudeInvalid: "La longitud debe ser un número entre -180 y 180",
        maxNumberOfCharacters: "Máx. {NUMBER} caracteres",
        missingDigitInPassword:
          "La contraseña debe tener al menos un número (123)",
        missingLowerCaseInPassword:
          "La contraseña debe tener al menos una letra minúscula (abc)",
        missingSpecialCharInPassword:
          "La contraseña debe tener al menos un carácter especial (! @ #)",
        missingUpperCaseInPassword:
          "La contraseña debe tener al menos una letra mayúscula (ABC)",
        numericFieldInvalid:
          "El valor debe ser numérico y no comenzar con cero",
        numericFieldLengthInvalid:
          "Introduce un valor con de {MIN} a {MAX} dígitos",
        partNumberInvalid: "Número de pieza no válido",
        passwordDoesNotMatch: "Las nuevas contraseñas deben coincidir",
        passwordTheSameAsOldOne:
          "La nueva contraseña no puede ser la misma que la antigua",
        passwordTooLong:
          "El número máximo de caracteres de la contraseña es 50",
        passwordTooShort: "La contraseña debe tener al menos 8 caracteres",
        priceListIdInvalid:
          "El valor debe comenzar con 2 letras mayúsculas y contener 2 dígitos",
        seriaNoInvalid: "Número de serie no válido",
        seriaNoItemExist: "El número de serie ya está en uso",
        seriaNoListEmpty: "La lista de números de serie no puede estar vacía",
        transferCodeInvalid: "Código de transferencia no válido"
      }
    }
  },
  login: {
    buttons: {
      labels: {
        sendPasswordResetEmail: "Enviar"
      }
    },
    changePassword: "Cambiar contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
    contactUs: "Ponte en contacto con nosotros",
    email: "Correo electrónico",
    forgotPassword: "He olvidado la contraseña",
    forgotPasswordQuestion: "¿Has olvidado tu contraseña?",
    newPassword: "Nueva contraseña",
    oldPassword: "Contraseña anterior",
    password: "Contraseña",
    passwordHasChanged: "Tu contraseña ha sido cambiada",
    passwordResetCheckEmail:
      "Consulta tu correo electrónico para obtener más instrucciones sobre cómo restablecer tu contraseña.",
    passwordResetDescription:
      "Introduce la dirección de correo electrónico verificada de tu cuenta de usuario y te enviaremos un enlace para restablecer la contraseña.",
    passwordStrength: "Seguridad de la contraseña",
    pleaseLoginWithNewPassword: "Inicia sesión con tu nueva contraseña",
    sessionHasExpired:
      "Tu inicio de sesión ha expirado. Inicia sesión de nuevo.",
    signIn: "INICIAR SESIÓN",
    signOut: "Cerrar sesión"
  },
  mainMenu: {
    documents: "Documentos",
    downloads: "Descargas",
    help: "Ayuda",
    home: "Inicio",
    messages: "Mensajes",
    webshop: "Tienda web"
  },
  mapInfoWindow: {
    machineSerialNo: "N.º de serie",
    missingMachineImage: "Falta imagen del producto"
  },
  messages: {
    title: "Mensajes"
  },
  store: {
    errors: {
      awsFailedToPutItem: null,
      couldNotConnectToBackendServer: "No se pudo conectar al servidor backend",
      error: "Error",
      failedToAddItem: "No se pudo añadir el elemento",
      failedToDeleteUser: "No se pudo borrar el usuario",
      failedToFechItem: "No se pudo recuperar el elemento",
      failedToFetchUserDetails:
        "No se pudieron recuperar los detalles del usuario",
      failedToSaveItem: "No se pudo guardar el elemento",
      failedToSendEmailNotification:
        "No se pudo enviar la notificación por correo electrónico",
      failedToUpdateUserDetails:
        "No se pudieron actualizar los detalles del usuario"
    }
  },
  termsOfService: {
    agreeAndContinue: "Aceptar y continuar",
    agreeTerms: "Estoy de acuerdo con {TERMS} y {POLICY}.",
    description:
      "Antes de poder comenzar a usar {APP_TITLE}, debes aceptar y estar de acuerdo con las Condiciones de servicio y la Política de privacidad.",
    privacyPolicy: "Política de privacidad",
    title: "Condiciones de servicio"
  }
}
