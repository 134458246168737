export default {
  $vuetify: {
    badge: "Badge",
    calendar: {
      moreEvents: "{0} more"
    },
    carousel: {
      ariaLabel: {
        delimiter: "Carousel slide {0} of {1}"
      },
      next: "Next visual",
      prev: "Previous visual"
    },
    close: "Close",
    dataFooter: {
      firstPage: "First page",
      itemsPerPageAll: "All",
      itemsPerPageText: "Items per page:",
      lastPage: "Last page",
      nextPage: "Next page",
      pageText: "{0}-{1} of {2}",
      prevPage: "Previous page"
    },
    dataIterator: {
      loadingText: "Loading... Please wait",
      noResultsText: "No matching records found"
    },
    dataTable: {
      ariaLabel: {
        activateAscending: "Activate to sort ascending.",
        activateDescending: "Activate to sort descending.",
        activateNone: "Activate to remove sorting.",
        sortAscending: "Sorted ascending.",
        sortDescending: "Sorted descending.",
        sortNone: "Not sorted."
      },
      itemsPerPageText: "Rows per page:",
      sortBy: "Sort by"
    },
    datePicker: {
      itemsSelected: "{0} selected",
      nextMonthAriaLabel: "Next month",
      nextYearAriaLabel: "Next year",
      prevMonthAriaLabel: "Previous month",
      prevYearAriaLabel: "Previous year"
    },
    fileInput: {
      counter: "{0} files",
      counterSize: "{0} files ({1} in total)"
    },
    noDataText: "No data available",
    pagination: {
      ariaLabel: {
        currentPage: "Current Page Page {0}",
        next: "Next page",
        page: "Goto Page {0}",
        previous: "Previous page",
        wrapper: "Pagination Navigation"
      }
    },
    timePicker: {
      am: "AM",
      pm: "PM"
    }
  },
  chart: {
    legend: {
      average: "Average",
      hours: "Hours",
      max: "Max",
      pressure: "Pressure",
      temperature: "Temperature"
    }
  },
  common: {
    actions: {
      add: "Add",
      apply: "Apply",
      applyChanges: "Apply changes",
      cancel: "Cancel",
      close: "Close",
      download: "Download",
      edit: "Edit",
      exportAsCSV: "Export as csv",
      goBack: "Go back",
      hide: "Hide",
      move: "Move",
      next: "Next",
      no: "No",
      ok: "OK",
      openInNewWindow: "Opens in new window",
      previous: "Previous",
      refresh: "Refresh",
      remove: "Remove",
      retry: "Retry",
      save: "Save",
      show: "Show",
      transfer: "Transfer",
      view: "View",
      yes: "Yes"
    },
    allRightsReserved: "All rights reserved",
    dataTable: {
      headers: {
        actions: "Actions"
      }
    },
    default: "Default",
    dialogs: {
      confirmRemove: {
        confirm: "Remove the selected {0}?",
        note: "Note: This action cannot be undone.",
        title: "Remove {0}"
      }
    },
    enums: {
      day: {
        now: "Now",
        today: "Today",
        yesterday: "Yesterday"
      },
      measurementSystem: {
        imperial: "Imperial",
        metric: "Metric"
      },
      time: {
        hours: "Hours",
        minutes: "Minutes",
        seconds: "Seconds"
      }
    },
    error: {
      causedBy: "Caused by",
      details: "Details",
      more: "More",
      request: "Request"
    },
    exceptions: {
      error: "Error"
    },
    filters: {
      customRange: "Custom range",
      filter: "Filter",
      last: "Last",
      timeRange: "Time range"
    },
    invalidUsage: "Invalid usage",
    language: "Language",
    messages: {
      noDataToExport:
        "There is no data to export. Please select a different time range.",
      recordsExported: "{number} records were exported"
    },
    placeholders: {
      email: "Enter email address",
      selectDates: "Select dates"
    },
    saveSuceeded: "Changes have been saved",
    units: {
      day: "{count} days | {count} day | {count} days",
      daysAgo: "{count} days ago | {count} day ago | {count} days ago",
      hour: "{count} hours | {count} hour | {count} hours",
      hourShort: "H",
      month: "{count} months | {count} month | {count} months",
      second: "{count} seconds | {count} second | {count} seconds",
      week: "{count} weeks | {count} week | {count} weeks"
    },
    validation: {
      errors: {
        betweenInvalid: "Value must be between {MIN} and {MAX}",
        commentMaxChars: "Comment must be 256 characters or less",
        customerNoInvalid: "Please enter a valid customer no ",
        discountInvalid: "The value must be numeric and between 0 and 100",
        emailInvalid: "Invalid Email address",
        emailLengthInvalid: "Invalid Email address length.",
        engineHoursInvalid: "Invalid engine hours",
        fieldIsRequired: "Required",
        fieldLengthBetween: "Must be between {MIN} and {MAX} characters",
        fieldMustBeginWithWord: "Must begin with a word character",
        latitudeInvalid: "Latitude must be a number between -90 and 90",
        longitudeInvalid: "Longitude must be a number between -180 and 180",
        maxNumberOfCharacters: "Max {NUMBER} characters",
        missingDigitInPassword: "Password needs atleast one number (123)",
        missingLowerCaseInPassword:
          "Password needs atleast one lowercase letter (abc)",
        missingSpecialCharInPassword:
          "Password needs atleast one special character (!@#)",
        missingUpperCaseInPassword:
          "Password needs atleast one uppercase letter (ABC)",
        numericFieldInvalid:
          "The value must be numeric and not start with zero",
        numericFieldLengthInvalid:
          "Please enter a value with {MIN} to {MAX} digits",
        partNumberInvalid: "Invalid part number",
        passwordDoesNotMatch: "New passwords must match",
        passwordTheSameAsOldOne:
          "New password cannot be the same as old password",
        passwordTooLong: "Password maximum characters is 50",
        passwordTooShort: "Password need atleast 8 characters",
        priceListIdInvalid:
          "The value must start with 2 upper-case letters and contain 2 digits",
        seriaNoInvalid: "Invalid serial number",
        seriaNoItemExist: "Serial number is in use",
        seriaNoListEmpty: "Serial number list can't be empty",
        transferCodeInvalid: "Invalid transfer code"
      }
    }
  },
  login: {
    buttons: {
      labels: {
        sendPasswordResetEmail: "Send"
      }
    },
    changePassword: "Change password",
    confirmNewPassword: "Confirm new password",
    contactUs: "Contact us",
    email: "Email",
    forgotPassword: "Forgot passowrd",
    forgotPasswordQuestion: "Forgot password?",
    newPassword: "New password",
    oldPassword: "Old password",
    password: "Password",
    passwordHasChanged: "Your password has been changed",
    passwordResetCheckEmail:
      "Please check your email for further instructions how to reset your password.",
    passwordResetDescription:
      "Enter your user account's verified email address and we will send you a password reset link.",
    passwordStrength: "Password strength",
    pleaseLoginWithNewPassword: "Please login with your new password",
    sessionHasExpired: "Your login session has expired. Please login again.",
    signIn: "SIGN IN",
    signOut: "Sign out"
  },
  mainMenu: {
    documents: "Documents",
    downloads: "Downloads",
    help: "Help",
    home: "Home",
    messages: "Messages",
    webshop: "Webshop"
  },
  mapInfoWindow: {
    machineSerialNo: "Serial no",
    missingMachineImage: "Missing product image"
  },
  messages: {
    title: "Messages"
  },
  store: {
    errors: {
      awsFailedToPutItem: "Failed to put item in AWS",
      couldNotConnectToBackendServer: "Could not connect to backend server",
      error: "Error",
      failedToAddItem: "Failed to add item",
      failedToDeleteUser: "Failed to delete user",
      failedToFechItem: "Failed to fetch item",
      failedToFetchUserDetails: "Failed to fetch user details",
      failedToSaveItem: "Failed to save item",
      failedToSendEmailNotification: "Failed to send email notification",
      failedToUpdateUserDetails: "Failed to update user details"
    }
  },
  termsOfService: {
    agreeAndContinue: "Agree and Continue",
    agreeTerms: "I agree to the {TERMS} and {POLICY}.",
    description:
      "Before you can start using {APP_TITLE}, you must accept and agree to the Terms of Service and Privacy Policy.",
    privacyPolicy: "Privacy policy",
    title: "Terms of service"
  }
}
