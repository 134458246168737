import fetchClient from "@/utils/fetchClient"

export default {
  state: {
    warrantyClaims: {
      links: [
        {
          type: "HTTPLink",
          displayname: "AM system login",
          file: "https://rv456.amsystem.com/",
          icon: "mdi-open-in-new"
        },
        {
          type: "S3",
          displayname: "Warranty procedures",
          file:
            "1282_1_Warranty_procedures_for_Subsidiary_companies__Distributors_Agents.pdf",
          icon: "mdi-download"
        }
      ]
    },
    returnForm: {
      links: [
        {
          type: "S3",
          displayname: "Return Form",
          file: "RETURN_FORM2.PDF",
          icon: "mdi-rotate-360"
        }
      ]
    },
    serviceProtocols: {
      links: [
        {
          type: "S3",
          displayname: "Service 1000-hours",
          file: "Service_1000-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 500-hours",
          file: "Service_500-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 250-hours",
          file: "Service_250-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 40-hours",
          file: "Service_40-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service weekly",
          file: "Service_weekly.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service yearly",
          file: "Service_yearly.pdf",
          icon: "mdi-folder-wrench-outline"
        }
      ]
    },
    serviceManuals: {
      links: [
        {
          type: "S3",
          displayname: "Brokk 40 970201-",
          file: "800955.PDF",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk MiniCut 970001-970200",
          file: "800675-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 90 920001-",
          file: "801124-GBB.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 180 940800-",
          file: "801107-GBD.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 250 910540-910647",
          file: "801076-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 250 910648-",
          file: "801082-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 330 digital 980001-",
          file: "800965-B.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 330 analog 980001-",
          file: "800966-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname:
            "Brokk 110/150 digital 940200-940479, 940481-940504, 940507-940576, 940579-940589",
          file: "800643-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname:
            "Brokk 110/150 digital 940480, 940505-940506, 940577-940578, 940590-",
          file: "801000-b.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 110/150 analog 940200-",
          file: "801034-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, United Kingdom",
          file: "3136 8092 94_Service_(1.2)_en_GB.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, Sweden",
          file: "3136 8092 94_Service_(1.2)_sv_SE.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, Electrical Diagram",
          file: "Service Electrical diagram B70-B500 3136 8092 50-B.pdf",
          icon: "mdi-tools"
        }
      ]
    },
    spareParts: {
      links: [
        {
          type: "S3",
          displayname: "Brokk 40 Rev.A3 970395-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8015 97-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 40 Rev.A1 970360-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8013 22-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 40 Rev.A 970309-970359",
          description: "Mechanical, Electric cabinet",
          file: "801156-K.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 40 970201-970308 Kabel/Cable",
          description: "Mechanical, Electric cabinet",
          file: "800846-N.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 40 970201-970308 Radio",
          description: "Mechanical, Electric cabinet",
          file: "800844-N.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 40 Rev.A2 970384-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8014 69-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A5 971197-",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A5 971197-",
          description: "Mechanical",
          file: "3136 8045 47-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A4 971132-971196",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A4 971132-971196",
          description: "Mechanical",
          file: "3136 8034 45-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A3 971105-971131",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A3 971105-971131",
          description: "Mechanical",
          file: "3136 8031 05-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A2 971075-971104",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A2 971075-971104",
          description: "Mechanical",
          file: "3136 8027 75-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A1 971043-971074",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A1 971043-971074",
          description: "Mechanical",
          file: "3136 8024 81-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A 971001-971042",
          description: "Electric cabinet",
          file: "3136 8022 25-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 50 Rev. A 971001-971042",
          description: "Mechanical",
          file: "3136 8021 99-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A2 972350-",
          description: "Electric cabinet",
          file: "3136 8056 77-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A2 972350-",
          description: "Mechanical",
          file: "3136_8071_15-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A1 972290-972349",
          description: "Electric cabinet",
          file: "3136_8056_77-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A1 972290-972349",
          description: "Mechanical",
          file: "3136_8067_75-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A 972200-972289",
          description: "Electric cabinet",
          file: "3136_8056_77-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60II Rev. A 972200-972289",
          description: "Mechanical",
          file: "3136_8055_56-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60 972000-972199",
          description: "Electric cabinet",
          file: "3136 8047 59-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 60 972000-972199",
          description: "Mechanical",
          file: "3136 8046 36-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 Rev. B 973350-",
          description: "Electric cabinet",
          file: "3136 8076 53-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 Rev. B 973350-",
          description: "Mechanical",
          file: "3136 8087 97-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 Rev. A 973156-973349",
          description: "Mechanical",
          file: "3136_8087_97-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 Rev. A 973156-973349",
          description: "Electric cabinet",
          file: "3136 8076 53-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 973000-973155",
          description: "Electric cabinet",
          file: "3136 8076 53-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 70 973000-973155",
          description: "Mechanical",
          file: "3136_8076_54-J.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E5 921215-",
          description: "Electric cabinet",
          file: "3136 8028 81-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E5 921215-",
          description: "Mechanical",
          file: "3136 8040 19-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E4 921067-921214",
          description: "Electric cabinet",
          file: "3136 8028 81-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E4 921067-921214",
          description: "Mechanical",
          file: "3136 8032 50-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E3 921000-921066",
          description: "Electric cabinet",
          file: "3136 8028 81-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E3 921000-921066",
          description: "Mechanical",
          file: "3136 8028 42-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E2 920633-920969",
          description: "Mechanical, Electric cabinet",
          file: "3136 8017 41-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E1 920600-920632",
          description: "Mechanical, Electric cabinet",
          file: "3136 8016 99-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev. E 920505-920599",
          description: "Mechanical, Electric cabinet",
          file: "3136 8015 78-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev D1  920475-920504",
          description: "Mechanical, Electric cabinet",
          file: "3136 8015 18-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev D  920415-920474",
          description: "Mechanical, Electric cabinet",
          file: "3136 8013 83-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev C  920350-920414",
          description: "Mechanical, Electric cabinet",
          file: "801275-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev B  920306-920349",
          description: "Mechanical, Electric cabinet",
          file: "801213-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 90 Rev B  920201-920305",
          description: "Mechanical, Electric cabinet",
          file: "3136 8012 05-M.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. C 922000-",
          description: "Electric cabinet",
          file: "3136 8059 37-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. C 922000-",
          description: "Mechanical",
          file: "3136_8059_39-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. B 921600-",
          description: "Electric cabinet",
          file: "3136 8054 09-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. B 921600-",
          description: "Mechanical",
          file: "3136 8054 21-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A3 921520-921599",
          description: "Electric cabinet",
          file: "3136 8048 79-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A3 921520-921599",
          description: "Mechanical",
          file: "3136 8050 97-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A2 921480-921519",
          description: "Electric cabinet",
          file: "3136 8048 79-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A2 921480-921519",
          description: "Mechanical",
          file: "3136 8048 53-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A1 921430-921479",
          description: "Electric cabinet",
          file: "3136 8047 28-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A1 921430-921479",
          description: "Mechanical",
          file: "3136 8045 92-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A 921400-921429",
          description: "Electric cabinet",
          file: "3136 8040 86-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 Rev. A 921400-921429",
          description: "Mechanical",
          file: "3136 8045 07-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 921300-921399",
          description: "Electric cabinet",
          file: "3136 8040 86-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 100 921300-921399",
          description: "Mechanical",
          file: "3136 8039 31-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. B 923290-",
          description: "Electric cabinet",
          file: "3136 8087 14-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. B 923290-",
          description: "Mechanical",
          file: "3136 8087 31-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A3 922870-923289",
          description: "Electric cabinet",
          file: "3136 8087 14-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A3 922870-923289",
          description: "Mechanical",
          file: "3136_8087_31-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A2 922770-922869",
          description: "Electric cabinet",
          file: "3136 8087 14-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A2 922770-922869",
          description: "Mechanical",
          file: "3136_8085_11-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A1 922350-922769",
          description: "Electric cabinet",
          file: "3136 8062 62-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A1 922350-922769",
          description: "Mechanical",
          file: "3136_8071_54-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A 922290-922349",
          description: "Electric cabinet",
          file: "3136 8062 62-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D Rev. A 922290-922349",
          description: "Mechanical",
          file: "3136 8069 86-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D 922100-922289",
          description: "Electric cabinet",
          file: "3136 8062 62-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D 922100-922289",
          description: "Mechanical",
          file: "3136 8062 84-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D 922000-922099",
          description: "Electric cabinet",
          file: "3136 8062 62-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 120D 922000-922099",
          description: "Mechanical",
          file: "3136 8062 60-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B2 942370-",
          description: "Electric cabinet",
          file: "3136_8048_16-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B2 942370-",
          description: "Mechanical",
          file: "3136_8054_29-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B2 942370-",
          description: "Main valve",
          file: "3136 8048 18-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B1 942310-942369",
          description: "Electric cabinet",
          file: "3136_8048_16-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B1 942310-942369",
          description: "Mechanical",
          file: "3136_8050_27-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B1 942310-942369",
          description: "Main valve",
          file: "3136 8048 18-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B 942250-942309",
          description: "Electric cabinet",
          file: "3136_8048_16-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B 942250-942309",
          description: "Mechanical",
          file: "3136_8048_45-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. B 942250-942309",
          description: "Main valve",
          file: "3136 8048 18-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A1 942240-942249",
          description: "Electric cabinet",
          file: "3136 8040 43-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A1 942240-942249",
          description: "Mechanical",
          file: "3136 8047 72-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A1 942240-942249",
          description: "Main valve",
          file: "3136 8035 87-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A 942110-942239",
          description: "Electric cabinet",
          file: "3136 8040 43-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A 942110-942239",
          description: "Mechanical",
          file: "3136 8040 26-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 Rev. A 942110-942239",
          description: "Main valve",
          file: "3136 8035 87-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 942000-942109",
          description: "Electric cabinet",
          file: "3136 8036 31-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 942000-942109",
          description: "Mechanical",
          file: "3136 8035 12-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 160 942000-942109",
          description: "Main valve",
          file: "3136 8035 87-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. B 943490-",
          description: "Electric cabinet",
          file: "3136 8069 99-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. B 943490-",
          description: "Mechanical",
          file: "3136 8087 90-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. A1 943305-943489",
          description: "Electric cabinet",
          file: "3136 8069 99-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. A1 943305-943489",
          description: "Mechanical",
          file: "3136_8087_90-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. A 943060-943304",
          description: "Electric cabinet",
          file: "3136 8069 99-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 Rev. A 943060-943304",
          description: "Mechanical",
          file: "3136_8074_91-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 943000-943059",
          description: "Electric cabinet",
          file: "3136 8069 99-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 170 943000-943059",
          description: "Mechanical",
          file: "3136 8070 38-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. G 941760-",
          description: "Electric cabinet",
          file: "3136 8024 32-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. G 941760-",
          description: "Mechanical",
          file: "3136 8037 98-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. G 941760-",
          description: "Main valve",
          file: "3136 8024 99-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F2 941560-941759",
          description: "Electric cabinet",
          file: "3136 8024 32-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F2 941560-941759",
          description: "Mechanical",
          file: "3136 8028 37-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F2 941560-941759",
          description: "Main valve",
          file: "3136 8024 99-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F1 941469-941559",
          description: "Electric cabinet",
          file: "3136 8024 32-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F1 941469-941559",
          description: "Mechanical",
          file: "3136 8025 91-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F1 941469-941559",
          description: "Main valve",
          file: "3136 8024 99-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F 941450-941468",
          description: "Electric cabinet",
          file: "3136 8024 32-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F 941450-941468",
          description: "Mechanical",
          file: "3136 8024 17-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. F 941450-941468",
          description: "Main valve",
          file: "3136 8024 99-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E2 941370-941449",
          description: "Mechanical, Electric cabinet",
          file: "3136 8021 96-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E2 941370-941449",
          description: "Mechanical",
          file: "3136 8022 10-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E1 941299-941369",
          description: "Mechanical, Electric cabinet",
          file: "3136 8017 42-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E1 941299-941369",
          description: "Mechanical",
          file: "3136 8017 11-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E 941210-941298",
          description: "Mechanical, Electric cabinet",
          file: "3136 8016 98-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. E 941210-941298",
          description: "Mechanical",
          file: "3136 8017 11-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. D 941170-941209",
          description: "Mechanical, Electric cabinet",
          file: "3136 8016 48-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. D 941170-941209",
          description: "Main valve",
          file: "3136 8015 01-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. C4  941130-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8015 80-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. C3  941098-9411129",
          description: "Mechanical, Electric cabinet",
          file: "3136 8014 93-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. C2  941070-941097",
          description: "Mechanical, Electric cabinet",
          file: "3136 8014 41-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. C1  941033-941069",
          description: "Mechanical, Electric cabinet",
          file: "3136 8013 74-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. C  941003-941032",
          description: "Mechanical, Electric cabinet",
          file: "3136 8013 09-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. B  940955-941002",
          description: "Mechanical, Electric cabinet",
          file: "3136 8012 47-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 180 Rev. A 940844, 940875-940954",
          description: "Mechanical, Electric cabinet",
          file: "801174-Z.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 Rev. B 951480-",
          description: "Electric cabinet",
          file: "3136 8086 41-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 Rev. B 951480-",
          description: "Mechanical",
          file: "3136 8086 42-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 Rev. A 951230-951479",
          description: "Electric cabinet",
          file: "3136 8086 41-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 Rev. A 951230-951479",
          description: "Mechanical",
          file: "3136 8086 42-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 951000-951229",
          description: "Electric cabinet",
          file: "3136 8070 05-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 200 951000-951229",
          description: "Mechanical",
          file: "3136_8070_40-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B3 911253-",
          description: "Electric cabinet",
          file: "3136 8040 79-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B3 911253-",
          description: "Mechanical",
          file: "3136_8052_86-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B3 911253-",
          description: "Main valve",
          file: "3136 8048 80-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B2 911210-911252",
          description: "Electric cabinet",
          file: "3136 8040 79-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B2 911210-911252",
          description: "Mechanical",
          file: "3136 8047 69-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B2 911210-911252",
          description: "Main valve",
          file: "3136 8048 80-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B1 911145-911209",
          description: "Electric cabinet",
          file: "3136 8040 79-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B1 911145-911209",
          description: "Mechanical",
          file: "3136 8043 19-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B1 911145-911209",
          description: "Main valve",
          file: "3136 8031 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B 911120-911144",
          description: "Electric cabinet",
          file: "3136 8040 79-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B 911120-911144",
          description: "Mechanical",
          file: "3136 8040 62-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. B 911120-911144",
          description: "Main valve",
          file: "3136 8031 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A1 911075-911119",
          description: "Electric cabinet",
          file: "3136 8031 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A1 911075-911119",
          description: "Mechanical",
          file: "3136 8037 89-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A1 911075-911119",
          description: "Main valve",
          file: "3136 8031 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A 911022-911074",
          description: "Electric cabinet",
          file: "3136 8031 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A 911022-911074",
          description: "Mechanical",
          file: "3136 8033 01-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 Rev. A 911022-911074",
          description: "Main valve",
          file: "3136 8031 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 911000-911021",
          description: "Electric cabinet",
          file: "3136 8031 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 911000-911021",
          description: "Mechanical",
          file: "3136 8031 33-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 260 911000-911021",
          description: "Main valve",
          file: "3136 8031 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 280 911350-",
          description: "Electric cabinet",
          file: "3136 8059 54-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 280 911350-",
          description: "Mechanical",
          file: "3136_8060_31-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 280 911350-",
          description: "Main valve",
          file: "3136 8048 80-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. C 982340-",
          description: "Electric cabinet",
          file: "3136 8086 27-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. C 982340-",
          description: "Mechanical",
          file: "3136 8086 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. B 912100-982339",
          description: "Electric cabinet",
          file: "3136 8086 27-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. B 912100-982339",
          description: "Mechanical",
          file: "3136_8086_35-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. A 912030-912099",
          description: "Electric cabinet",
          file: "3136 8069 15-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 Rev. A 912030-912099",
          description: "Mechanical",
          file: "3136_8073_09-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 912000-912029",
          description: "Electric cabinet",
          file: "3136 8069 15-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 300 912000-912029",
          description: "Mechanical",
          file: "3136 8069 74-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B6 980679-",
          description: "Electric cabinet",
          file: "3136 8017 93-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B6 980679-",
          description: "Mechanical",
          file: "3136 8043 98-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B6 980679-",
          description: "Main valve",
          file: "3136 8015 01-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B5 980643-980678",
          description: "Electric cabinet",
          file: "3136 8017 93-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B5 980643-980678",
          description: "Mechanical",
          file: "3136 8033 51-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B5 980643-980678",
          description: "Main valve",
          file: "3136 8015 01-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B4 980633-980642",
          description: "Electric cabinet",
          file: "3136 8017 93-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B4 980633-980642",
          description: "Mechanical",
          file: "3136 8031 46-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B4 980633-980642",
          description: "Main valve",
          file: "3136 8015 01-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B3 980600-980632",
          description: "Electric cabinet",
          file: "3136 8017 93-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B3 980600-980632",
          description: "Mechanical",
          file: "3136 8027 98-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B3 980600-980632",
          description: "Main valve",
          file: "3136 8015 01-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B2 980402-980599",
          description: "Electric cabinet",
          file: "3136 8017 93-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B2 980402-980599",
          description: "Mechanical",
          file: "3136 8017 82-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B2 980402-980599",
          description: "Main valve",
          file: "3136 8015 01-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B 980310-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8014 36-O.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B 980310-",
          description: "Main valve",
          file: "3136 8015 01-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B 980277",
          description: "Mechanical, Electric cabinet",
          file: "801392-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B 980201-",
          description: "Mechanical, Electric cabinet",
          file: "801128-a.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 330 & 330D Rev. B 980224, 980232",
          description: "Mechanical, Electric cabinet",
          file: "801267-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400D Rev. B3 981410-",
          description: "Electric cabinet",
          file: "3136 8067 02-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400D Rev. B3 981410-",
          description: "Mechanical",
          file: "3136_8067_01-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400D Rev. B3 981410-",
          description: "Main valve",
          file: "3136 8046 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B2 981400-",
          description: "Electric cabinet",
          file: "3136 8046 65-L.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B2 981400-",
          description: "Electric cabinet diesel",
          file: "3136 8067 02-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B2 981400-",
          description: "Mechanical",
          file: "3136_8065_73-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B2 981400-",
          description: "Main valve",
          file: "3136 8046 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B1 981259-981399",
          description: "Electric cabinet",
          file: "3136 8046 65-L.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B1 981259-981399",
          description: "Mechanical",
          file: "3136_8053_30-K.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B1 981259-981399",
          description: "Main valve",
          file: "3136 8046 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B 981200-981258",
          description: "Electric cabinet",
          file: "3136 8046 65-L.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B 981200-981258",
          description: "Mechanical",
          file: "3136_8046_86-M.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 & 400D Rev. B 981200-981258",
          description: "Main valve",
          file: "3136 8046 66-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A3 981112-981199",
          description: "Electric cabinet",
          file: "3136 8040 76-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A3 981112-981199",
          description: "Mechanical",
          file: "3136 8041 48-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A3 981112-981199",
          description: "Main valve",
          file: "3136 8031 07-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A2 981100-981111",
          description: "Electric cabinet",
          file: "3136 8040 76-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A2 981100-981111",
          description: "Mechanical",
          file: "3136 8040 23-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A2 981100-981111",
          description: "Main valve",
          file: "3136 8031 07-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A1 981042-981099",
          description: "Electric cabinet",
          file: "3136 8029 27-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A1 981042-981099",
          description: "Mechanical",
          file: "3136 8035 16-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A1 981042-981099",
          description: "Main valve",
          file: "3136 8031 07-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A 981027-981041",
          description: "Electric cabinet",
          file: "3136 8029 27-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A 981027-981041",
          description: "Mechanical",
          file: "3136 8032 99-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 Rev. A 981027-981041",
          description: "Main valve",
          file: "3136 8031 07-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 981000-981026",
          description: "Electric cabinet",
          file: "3136 8029 27-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 981000-981026",
          description: "Mechanical",
          file: "3136 8028 73-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 400 981000-981026",
          description: "Main valve",
          file: "3136 8031 07-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. D 982340-",
          description: "Electric cabinet",
          file: "3136 8065 42-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. D 982340-",
          description: "Mechanical",
          file: "3136 8081 43-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. C 982300-982339",
          description: "Electric cabinet",
          file: "3136 8065 42-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. C 982300-982339",
          description: "Mechanical",
          file: "3136 8081 43-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. B 982120-982299",
          description: "Electric cabinet",
          file: "3136 8065 42-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. B 982120-982299",
          description: "Mechanical",
          file: "3136_8081_43-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. A 982050-982119",
          description: "Electric cabinet",
          file: "3136 8065 42-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. A 982050-982119",
          description: "Mechanical",
          file: "3136_8071_42-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 Rev. A 982050-982119",
          description: "Main valve",
          file: "3136 8065 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 982000-982049",
          description: "Electric cabinet",
          file: "3136 8065 42-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 982000-982049",
          description: "Mechanical",
          file: "3136_8064_71-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 500 982000-982049",
          description: "Main valve",
          file: "3136 8065 56-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Electric cabinet Diesel V",
          file: "3136 8090 04-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Mechanical Diesel V",
          file: "3136 8090 51-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982300-982339",
          description: "Electric cabinet Diesel V",
          file: "3136 8090 04-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982300-982339",
          description: "Mechanical Diesel V",
          file: "3136 8090 51-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Electric cabinet Diesel IV",
          file: "3136 8072 56-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Mechanical Diesel IV",
          file: "3136 8086 76-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982300-982339",
          description: "Electric cabinet Diesel IV",
          file: "3136 8086 76-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982300-982339",
          description: "Mechanical Diesel IV",
          file: "3136 8086 75-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Electric cabinet Diesel III",
          file: "3136 8072 75-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. D 982340-",
          description: "Mechanical Diesel III",
          file: "3136 8086 75-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982300-982339",
          description: "Electric cabinet Diesel III",
          file: "3136 8072 75-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. C 982120-982339",
          description: "Mechanical Diesel III",
          file: ".pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982339",
          description: "Electric cabinet Diesel IV",
          file: "3136 8072 56-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982339",
          description: "Mechanical Diesel IV",
          file: "3136_8086_76-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982339",
          description: "Electric cabinet Diesel III",
          file: "3136 8072 75-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982339",
          description: "Mechanical Diesel III",
          file: "3136_8086_75-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982299",
          description: "Kohler Diesel IV",
          file: "Diesel_IV.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. B 982120-982299",
          description: "Kubota Diesel III",
          file: "Diesel_III.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Electric cabinet Diesel IV",
          file: "3136 8072 56-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Electric cabinet Diesel III",
          file: "3136 8072 75-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Mechanical Diesel III",
          file: "3136 8088 74-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Mechanical Diesel IV",
          file: "3136 8088 75-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Kohler Diesel IV",
          file: "Diesel_IV.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 520D Rev. A 982050-982119",
          description: "Kubota Diesel III",
          file: "Diesel_III.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B5 900050-",
          description: "Electric cabinet",
          file: "3136 8048 50-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B5 900050-",
          description: "Mechanical",
          file: "3136_8071_10-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B5 900050-",
          description: "Main valve",
          file: "3136 8038 70-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B4 900020-",
          description: "Electric cabinet",
          file: "3136 8048 50-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B4 900020-",
          description: "Mechanical",
          file: "3136_8054_79-I.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B4 900020-",
          description: "Main valve",
          file: "3136 8038 70-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B3 900012-900019",
          description: "Electric cabinet",
          file: "3136 8048 50-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B3 900012-900019",
          description: "Mechanical",
          file: "3136 8051 89-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B3 900012-900019",
          description: "Main valve",
          file: "3136 8038 70-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B1 900008-",
          description: "Electric cabinet",
          file: "3136 8048 50-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B1 900008-",
          description: "Mechanical",
          file: "3136_8048_40-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 800 Rev. B1 900008-",
          description: "Main valve",
          file: "3136 8038 70-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 900 901000-",
          description: "Mechanical, Electric cabinet",
          file: "3136_8087_72-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 250 Dir-start 910765-910795",
          description: "Mechanical, Electric cabinet",
          file: "801050-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 250 Dir-start 910770-910809",
          description: "Mechanical, Electric cabinet",
          file: "801077-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk MiniCut 970182-970199",
          description: "Mechanical, Electric cabinet",
          file: "800775-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk MiniCut 970125-970181",
          description: "Mechanical, Electric cabinet",
          file: "800576-J.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. B 923290-",
          description: "Electric cabinet",
          file: "3136 8062 35-G.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. B 923290-",
          description: "Mechanical",
          file: "3136 8087 30-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A3",
          description: "Electric cabinet",
          file: "3136 8062 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A3",
          description: "Mechanical",
          file: "3136_8087_30-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A2",
          description: "Electric cabinet",
          file: "3136 8062 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A2",
          description: "Mechanical",
          file: "3136_8084_89-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A1",
          description: "Electric cabinet",
          file: "3136 8062 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A1",
          description: "Mechanical",
          file: "3136_8070_87-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A",
          description: "Electric cabinet",
          file: "3136 8062 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 Rev. A",
          description: "Mechanical",
          file: "3136_8069_50-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110",
          description: "Electric cabinet",
          file: "3136 8062 35-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110",
          description: "Mechanical",
          file: "3136_8063_23-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 digital",
          description: "This book serve as an example",
          file: "801037-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 150C digital",
          description: "This book serve as an example",
          file: "801016-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 150P analog",
          description: "This book serve as an example",
          file: "801115-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk 110 och 150 analog",
          description: "This book serve as an example",
          file: "801024-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk G32 9901 100-",
          description: "Mechanical, Electric cabinet",
          file: "3136_8067_99-Bgb.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk G50B 9902053-",
          description: "Mechanical, Electric cabinet",
          file: "3136_8020_35-Ngb.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk G30 9901 001-9901 003, 9901 005- ",
          description: "Mechanical, Electric cabinet",
          file: "3136_8031_13-Ggb.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk G50 9902 001-9902 005, 9902 007-9902052",
          description: "Mechanical, Electric cabinet",
          file: "800599-R.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC260, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC260, 6100 0903 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC260_D_Edition_12_10.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC320, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC320, 6320 0502 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC320_Edition_10_10.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC340, 6000 0519 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC340 Edition 13_11.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC400, Rev A. 960035-960299",
          description: "Mechanical, Electric cabinet",
          file: "800464-H.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC400, Rev A1. 960300-",
          description: "Mechanical, Electric cabinet",
          file: "3136 8014 97-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC420, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC420S, 6420 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC420_S_Edition_13_05.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC440, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC440, 6440 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC440_Ed18_09.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC480, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC480, 6440 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC480_Ed18_09.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC520, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC520, 6520 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC520_Ed_2010_11.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC560, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC560, 6560 0247 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC560_Edition_2010_10.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC580, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC580, 6580 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC580_Ed18_09.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC700, 6000 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "6000 0501 00-GB.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Concrete Crusher CC700S, 6700 0501 00",
          description: "Mechanical, Electric cabinet",
          file: "SPL_CC700,S_Edition_14_04.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "BCP - Brokk Concrete Planer",
          description: "BCP",
          file: "3136_8074_87-Dgb.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 55",
          description: "Mechanical",
          file: "BHB 55_3136 8071 25-C.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 105",
          description: "Mechanical",
          file: "BHB 105_3136 8071 45-F.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 105",
          description: "Mechanical",
          file: "BHB 105_3136 8088 02-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 155",
          description: "Mechanical",
          file: "BHB 155_3136 8071 18-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 155",
          description: "Mechanical",
          file: "BHB155_3136 8087 33-A_BES140590-.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 205",
          description: "Mechanical",
          file: "BHB 205_3136 8071 30-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 305",
          description: "Mechanical",
          file: "BHB 305_3136 8071 29-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 455",
          description: "Mechanical",
          file: "BHB 455_3136 8071 80-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 555",
          description: "Mechanical",
          file: "BHB 555_3136 8071 53-D.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 705",
          description: "Mechanical",
          file: "BHB 705_3136 8071 81-E.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 1300",
          description: "Mechanical",
          file: "BHB 1300_3136 8087 99-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Hydraulic Breaker BHB 1500",
          description: "Mechanical",
          file: "BHB 1500_3136 8087 98-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Drum Cutter BDC 40",
          description: "Mechanical",
          file: "SPL_BDC 40-X_3390815401.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Drum Cutter BDC 50",
          description: "Mechanical",
          file: "SPL_BDC50-4_3390807101.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Drum Cutter BDC 100",
          description: "Mechanical",
          file: "SPL_BDC100-4_3390807601.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Drum Cutter BDC 250",
          description: "Mechanical",
          file: "SPL_BDC250-3_3390807701.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk DMX520",
          description: "Mechanical",
          file: "800994-b.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Descaler Single 973156-",
          description: "Mechanical",
          file: "3136 8090 27-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Descaler Single 973156-",
          description: "Electric cabinet",
          file: "3136 8076 53-B.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Descaler Twin 973156-",
          description: "Mechanical",
          file: "3136 8090 29-A.pdf",
          icon: "mdi-download"
        },
        {
          type: "S3",
          displayname: "Brokk Descaler Twin 973156-",
          description: "Electric cabinet",
          file: "3136 8076 53-B.pdf",
          icon: "mdi-download"
        }
      ]
    }
  },
  actions: {
    async addServiceDocuments(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        let url = ""
        if (data.docType === "serviceInstructions") {
          url = "/api/v1/documents/serviceinstructions"
        } else {
          url = "/api/v1/documents/servicebulletins"
        }

        fetchClient(this.state.$i18n)
          .post(url, {
            partNo: data.partNo,
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async updateServiceInstructions(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        fetchClient(this.state.$i18n)
          .put("/api/v1/documents/serviceinstructions/" + data.partNo, {
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async updateServiceDocuments(context, data) {
      let url = ""
      if (data.docType === "serviceInstructions") {
        url = "/api/v1/documents/serviceinstructions/" + data.partNo
      } else {
        url = "/api/v1/documents/servicebulletins/" + data.partNo
      }

      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        fetchClient(this.state.$i18n)
          .put(url, {
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async removeServiceDocuments(context, data) {
      let url = ""
      if (data.docType === "serviceInstructions") {
        url = "/api/v1/documents/serviceinstructions/" + data.partNo
      } else {
        url = "/api/v1/documents/servicebulletins/" + data.partNo
      }
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)
        fetchClient(this.state.$i18n)
          .delete(url)
          .then(() => {
            context.commit("clearError")
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    }
  },
  getters: {
    warrantyClaims: state => {
      return state.warrantyClaims
    },
    returnForm: state => {
      return state.returnForm
    },
    serviceProtocols: state => {
      return state.serviceProtocols
    },
    serviceManuals: state => {
      return state.serviceManuals
    },
    spareParts: state => {
      return state.spareParts
    }
  }
}
